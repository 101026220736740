<template>
  <div class="main" :style="{ position: fixedStatus ? 'fixed' : 'relative' }">
    <div class="bg-area">
      <div class="bg-area-left">
        <el-image
          class="bg-img-bk"
          :src="picture.bkl"
          :style="'left:' + bgPosition.bklPositionLeft"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <el-image
          class="bg-img-ml"
          :src="picture.mll"
          :style="'left:' + bgPosition.mllPositionLeft"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
      <div class="bg-area-right">
        <el-image
          class="bg-img-bk"
          :src="picture.bkr"
          :style="'right:' + bgPosition.bkrPositionRight"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <el-image
          class="bg-img-ml"
          :src="picture.mlr"
          :style="'right:' + bgPosition.mlrPositionRight"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </div>
    <div class="content">
      <div class="activity">
        <div class="activity-logo">
          <el-image
            :src="logoUrl"
            fit="contain"
          ></el-image>
        </div>
        <div class="activity-btn-area">
          <div class="activity-btn-area-item" v-if="audioEmpower">
            <i class="el-icon-microphone"></i>
          </div>
          <div class="activity-btn-area-item" @click="handleShouQuan" v-else>
            <i class="el-icon-turn-off-microphone"></i>
          </div>
          <el-button v-if="fixedStatus" plain @click="handleChangePageSize"
          >退出全屏</el-button>
          <el-button v-if="!fixedStatus" plain @click="handleChangePageSize"
          >进入全屏</el-button>
        </div>
        <div class="activity-name">{{shopName}}&小花豆千城万店</div>
        <div class="activity-data-count">
          <div class="activity-data-count-item">
            访问数：<span class="activity-data-count-item-val" id="access_count">0</span>
          </div>
          <div class="activity-data-count-item">
            订单数：<span class="activity-data-count-item-val" id="order_count">0</span>
          </div>
          <div class="activity-data-count-item">
            客户推荐数：<span class="activity-data-count-item-val" id="member_new">0</span>
          </div>
          <div class="activity-data-count-item">
            员工推荐数：<span class="activity-data-count-item-val" id="member_old">0</span>
          </div>
        </div>
        <div class="activity-carousel" v-if="purchasedList.length">
          <div class="activity-carousel-area">
            <div class="activity-carousel-item" v-for="item in purchasedList" :key="item.id" :style="item.is_read === 1 ? 'margin-left: 0' : 'margin-left: -20%'">
              <div v-if="item.user && item.user.avatar_url">
                <el-image
                  class="activity-carousel-item-avatar"
                  :src="item.user.avatar_url"
                  fit="contain"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
              <div v-else>
                <el-image
                  class="activity-carousel-item-avatar"
                  src=""
                  fit="contain"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
              <div class="activity-carousel-item-data">
                <div class="user-name">{{item.user ? item.user.name : '--'}}</div>
                <div class="user-data">{{item.amount}}元</div>
              </div>
            </div>
          </div>
        </div>
        <div class="activity-carousel" v-else>
          <div class="activity-carousel-nodata">暂无数据</div>
        </div>
        <div class="activity-total">
          <div class="activity-total-sales" id="order_amount">0</div>
          <div class="activity-duration">
            活动历时：<span class="activity-duration-time">{{activityDuration.hour}}</span> 时 <span class="activity-duration-time">{{activityDuration.minute}}</span> 分 <span class="activity-duration-time">{{activityDuration.second}}</span> 秒
          </div>
        </div>
        <div class="activity-list">
          <div class="activity-list-title">
            <div class="activity-list-title__item">分店业绩排名</div>
            <div class="activity-list-title__item">员工业绩排名</div>
            <div class="activity-list-title__item">拼团成功排名</div>
          </div>
          <div class="activity-list-area">
            <div class="activity-list-area__item">
              <div class="activity-list-area__item-content" v-if="shops.length">
                <div class="activity-list-item" v-for="(item, index) in shops" :key="item.id">
                  <div class="activity-list-item-rank" v-if="index === 0">
                    <el-image
                      class="activity-list-item-rank-icon"
                      :src="rank1"
                      fit="contain"
                    >
                      <div slot="error" class="image-slot"></div>
                    </el-image>
                  </div>
                  <div class="activity-list-item-rank" v-else-if="index === 1">
                    <el-image
                      class="activity-list-item-rank-icon"
                      :src="rank2"
                      fit="contain"
                    >
                      <div slot="error" class="image-slot"></div>
                    </el-image>
                  </div>
                  <div class="activity-list-item-rank" v-else-if="index === 2">
                    <el-image
                      class="activity-list-item-rank-icon"
                      :src="rank3"
                      fit="contain"
                    >
                      <div slot="error" class="image-slot"></div>
                    </el-image>
                  </div>
                  <div class="activity-list-item-rank" v-else></div>
                  <el-image
                    class="activity-list-item-avatar"
                    :src="item.photo ? item.photo : ''"
                    fit="contain"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <el-popover
                    placement="top-start"
                    :title="item.name ? item.name : ''"
                    width="200"
                    trigger="hover"
                    :content="'订单：' + (item.order_count ? item.order_count : 0) + '，金额：' + (item.order_amount ? item.order_amount : '0.00')"
                  >
                    <div class="activity-list-item-data" slot="reference">
                      <div class="user-name">{{item.name ? item.name : ''}}</div>
                      <div class="user-data">
                        <div class="user-data-item">
                          订单：<span class="user-data-item-val">{{item.order_count ? item.order_count : 0}}</span>
                        </div>
                        <div class="user-data-item">
                          金额：<span class="user-data-item-val">{{item.order_amount ? item.order_amount : '0.00'}}</span>
                        </div>
                      </div>
                    </div>
                  </el-popover>
                </div>
              </div>
              <div class="activity-list-area__item-content" v-else>
                <div class="activity-list-area__item-content-nodata">暂无数据</div>
              </div>
            </div>
            <div class="activity-list-area__item">
              <div class="activity-list-area__item-content" v-if="staffs.length">
                <div class="activity-list-item" v-for="(item, index) in staffs" :key="item.id">
                  <div class="activity-list-item-rank" v-if="index === 0">
                    <el-image
                      class="activity-list-item-rank-icon"
                      :src="rank1"
                      fit="contain"
                    >
                      <div slot="error" class="image-slot"></div>
                    </el-image>
                  </div>
                  <div class="activity-list-item-rank" v-else-if="index === 1">
                    <el-image
                      class="activity-list-item-rank-icon"
                      :src="rank2"
                      fit="contain"
                    >
                      <div slot="error" class="image-slot"></div>
                    </el-image>
                  </div>
                  <div class="activity-list-item-rank" v-else-if="index === 2">
                    <el-image
                      class="activity-list-item-rank-icon"
                      :src="rank3"
                      fit="contain"
                    >
                      <div slot="error" class="image-slot"></div>
                    </el-image>
                  </div>
                  <div class="activity-list-item-rank" v-else></div>
                  <el-image
                    class="activity-list-item-avatar"
                    :src="item.avatar_url ? item.avatar_url : ''"
                    fit="contain"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <el-popover
                    placement="top-start"
                    :title="item.name ? item.name : '--'"
                    width="200"
                    trigger="hover"
                    :content="'订单：' + (item.order_count ? item.order_count : 0) + '，金额：' + (item.order_amount ? item.order_amount : '0.00')"
                  >
                    <div class="activity-list-item-data" slot="reference">
                      <div class="user-name">{{item.name ? item.name : '--'}}</div>
                      <div class="user-data">
                        <div class="user-data-item">
                          订单：<span class="user-data-item-val">{{item.order_count ? item.order_count : 0}}</span>
                        </div>
                        <div class="user-data-item">
                          金额：<span class="user-data-item-val">{{item.order_amount ? item.order_amount : '0.00'}}</span>
                        </div>
                      </div>
                    </div>
                  </el-popover>
                </div>
              </div>
              <div class="activity-list-area__item-content" v-else>
                <div class="activity-list-area__item-content-nodata">暂无数据</div>
              </div>
            </div>
            <div class="activity-list-area__item">
              <div class="activity-list-area__item-content" v-if="staffs_tuan.length">
                <div class="activity-list-item" v-for="(item, index) in staffs_tuan" :key="item.p_staff_user_id">
                  <div class="activity-list-item-rank" v-if="index === 0">
                    <el-image
                      class="activity-list-item-rank-icon"
                      :src="rank1"
                      fit="contain"
                    >
                      <div slot="error" class="image-slot"></div>
                    </el-image>
                  </div>
                  <div class="activity-list-item-rank" v-else-if="index === 1">
                    <el-image
                      class="activity-list-item-rank-icon"
                      :src="rank2"
                      fit="contain"
                    >
                      <div slot="error" class="image-slot"></div>
                    </el-image>
                  </div>
                  <div class="activity-list-item-rank" v-else-if="index === 2">
                    <el-image
                      class="activity-list-item-rank-icon"
                      :src="rank3"
                      fit="contain"
                    >
                      <div slot="error" class="image-slot"></div>
                    </el-image>
                  </div>
                  <div class="activity-list-item-rank" v-else></div>
                  <el-image
                    class="activity-list-item-avatar"
                    :src="item.p_staff_user && item.p_staff_user.avatar_url ? item.p_staff_user.avatar_url : ''"
                    fit="contain"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <div class="activity-list-item-data" slot="reference">
                    <div class="user-name">{{item.p_staff_user && item.p_staff_user.name ? item.p_staff_user.name : '--'}}</div>
                    <div class="user-data">
                      <div class="user-data-item">
                        成团数：<span class="user-data-item-val">{{item.order_count ? item.order_count : 0}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <el-popover
                    placement="top-start"
                    :title="item.p_staff_user && item.p_staff_user.name ? item.p_staff_user.name : '--'"
                    width="200"
                    trigger="hover"
                    :content="'订单：' + (item.order_count ? item.order_count : 0) + '，金额：' + (item.order_amount ? item.order_amount : '0.00')"
                  >
                    <div class="activity-list-item-data" slot="reference">
                      <div class="user-name">{{item.p_staff_user && item.p_staff_user.name ? item.p_staff_user.name : '--'}}</div>
                      <div class="user-data">
                        <div class="user-data-item">
                          订单：<span class="user-data-item-val">{{item.order_count ? item.order_count : 0}}</span>
                        </div>
                        <div class="user-data-item">
                          金额：<span class="user-data-item-val">{{item.order_amount ? item.order_amount : '0.00'}}</span>
                        </div>
                      </div>
                    </div>
                  </el-popover> -->
                </div>
              </div>
              <div class="activity-list-area__item-content" v-else>
                <div class="activity-list-area__item-content-nodata">暂无数据</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <audio
      :src="audioUrl"
      ref="audio"
    >Your browser does not support the audio element.</audio>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shopName: '', // 店铺名称
      fixedStatus: false, // 全屏标识
      audioEmpower: false, // 是否授权播放音频播报
      isShouQuan: false, // 是否已经授权
      picture: { // 背景图片路径
        bkl: require("@/assets/bkl.png"),
        bkr: require("@/assets/bkr.png"),
        mll: require("@/assets/mll.png"),
        mlr: require("@/assets/mlr.png"),
      },
      bgPosition: {
        mllPositionLeft: '-25vh',
        mlrPositionRight: '-25vh',
        bklPositionLeft: '-42vh',
        bkrPositionRight: '-42vh',
      },
      nums: {
        order_amount: 0,
        access_count: 0,
        order_count: 0,
        member_new: 0,
        member_old: 0,
      },
      start_time: '', // 活动开始时间
      activityBegin: false, // 活动是否已经开始
      activityDuration: {
        hour: '00',
        minute: '00',
        second: '00',
      },
      audioUrl: require("@/assets/music/订单来了.mp3"), // 音频路径
      logoUrl: require("@/assets/gold_logo.png"), // logo图片路径
      rank1: require("@/assets/1.png"), // 第一名图标
      rank2: require("@/assets/2.png"), // 第二名图标
      rank3: require("@/assets/3.png"), // 第三名图标
      audio: null, // 音频对象
      purchasedList: [], // 购买用户 列表
      lastPurchasedList: [], // 上次查询到的 购买用户 列表
      shops: [], // 分店业绩排名列表
      staffs: [], // 员工业绩排名列表
      staffs_tuan: [], // 拼团成功排名列表
      titlename: [], // 员工名称
      datas: [], // 订单金额占比
      valdata: [], // 订单金额
      timer: null, // 初始化定时器
      timers: [], // 定时器列表(保存定时器)
      timerList: [], // 定时器列表(保存定时器)
      activityDurationTimer: null, // 初始化活动历时计时器
      orderAmountFontSize: '315px', // 成交金额字号（根据长度进行调整）
      sayData: [],
      sayIndex: 0,
    }
  },
  methods: {
    /**
     * 授权播报
     */
    handleShouQuan() {
      this.audioEmpower = true;
      this.isShouQuan = true;
      this.$refs.audio.muted = true;
      this.$refs.audio.play();
    },
    /**
     * 切换全屏
     */
    handleChangePageSize() {
      this.bgPosition = {
        mllPositionLeft: '-25vh',
        mlrPositionRight: '-25vh',
        bklPositionLeft: '-42vh',
        bkrPositionRight: '-42vh',
      }
      setTimeout(() => {
        this.bgPosition = {
          mllPositionLeft: '0',
          mlrPositionRight: '0',
          bklPositionLeft: '0',
          bkrPositionRight: '0',
        }
        this.fixedStatus = !this.fixedStatus;
      }, 1000)
    },
    /**
     * 数字初次渲染特效
     */
    variabledLoadingNum(obj, index) {
      const that = this;
      const timerListLen = this.timerList.length;
      if (index < timerListLen) {
        if (obj.value != this.timerList[index].value) {
          this.timerList[index].value = obj.value;
          document.querySelector(obj.dom).innerHTML = obj.value;
        }
        return false;
      } else {
        that.timerList[index] = obj;
        if (obj.value > 0) {
          that.timerList[index].step = obj.value / 20; // 步进值
          that.timerList[index].count = 0; // 计数器
          that.timerList[index].text = document.querySelector(obj.dom); // dom
          that.timerList[index].timer = setInterval(() => {
            that.timerList[index].count = that.timerList[index].count + that.timerList[index].step;
            if (that.timerList[index].count >= that.timerList[index].value) {
              that.timerList[index].count = that.timerList[index].value;
              clearInterval(that.timerList[index].timer);
            }
            that.timerList[index].text.innerHTML = Number(that.timerList[index].count.toFixed(2));
          }, 40);
        }
      }
    },
    /**
     * 获取数据
     */
    getList(params) {
      const parameter = params;
      this.$axios({
        href: "/api/app/meiye/wechat_order/teamindex",
        loading: true,
      }).then((res) => {
        if (res && res.data && res.data.successful === 1) {
          this.shops = res.data.data && res.data.data.shops ? res.data.data.shops : [];
          this.staffs = res.data.data && res.data.data.staffs ? res.data.data.staffs : [];
          this.staffs_tuan = res.data.data && res.data.data.staffs_tuan ? res.data.data.staffs_tuan : [];
          this.shopName = res.data.data && res.data.data.group_name ? res.data.data.group_name : '--';
          if (this.nums.access_count < res.data.data.access_count) {
            this.nums.access_count = res.data.data.access_count;
            this.variabledLoadingNum({value: res.data.data.access_count, dom: "#access_count"}, 0);
          }
          if (this.nums.order_count < res.data.data.order_count) {
            console.log('parameter', parameter);
            this.variabledLoadingNum({value: res.data.data.order_count, dom: "#order_count"}, 1);
            // 语音播报开始
            if (parameter) {
              const sayDataPushLen = res.data.data.order_count - this.nums.order_count;
              this.nums.order_count = res.data.data.order_count;
              if (this.isShouQuan && sayDataPushLen) {
                // 订单语音播报
                for (let j = 0; j < sayDataPushLen; j++) {
                  this.sayData.push({status: false})
                }
                this.sayNum();
              }
            } else {
              this.nums.order_count = res.data.data.order_count;
            }
            // 语音播报结束
          }
          if (this.nums.member_new < res.data.data.member_new) {
            this.nums.member_new = res.data.data.member_new;
            this.variabledLoadingNum({value: res.data.data.member_new, dom: "#member_new"}, 2);
          }
          if (this.nums.member_old < res.data.data.member_old) {
            this.nums.member_old = res.data.data.member_old;
            this.variabledLoadingNum({value: res.data.data.member_old, dom: "#member_old"}, 3);
          }
          if (this.nums.order_amount < res.data.data.order_amount) {
            this.nums.order_amount = res.data.data.order_amount;
            this.variabledLoadingNum({value: res.data.data.order_amount, dom: "#order_amount"}, 4);
          }
          if (this.start_time != res.data.data.start_time) {
            this.start_time = res.data.data.start_time;
            const timeStamp = new Date();
            const startTimeStamp = new Date(res.data.data.start_time);
            if (timeStamp > startTimeStamp) {
              this.activityTiming();
            }
          }
          let orderArr = res.data.data && res.data.data.order ? res.data.data.order : [];
          const orderArrLen = orderArr.length;
          if (orderArr && orderArrLen) {
            if (JSON.stringify(orderArr) != JSON.stringify(this.lastPurChasedList)) {
              this.lastPurChasedList = orderArr;
              this.purchasedList = orderArr;
              setTimeout(() => {
                this.purchasedList.forEach(item => {
                  item.is_read = 1;
                })
              }, 500)
            }
          }
        }
      });
    },
    /**
     * 语音播报
     */
    sayNum() {
      const that = this;
      const sayData = that.sayData;
      const sayDataLen = sayData.length;
      const sayDataLastIndex = sayData.length - 1;
      const index = that.sayIndex;
      let num = 0;
      let timer = null;
      for (let i = index; i < sayDataLen; i++) {
        if (sayData[i] && !sayData[i].status) {
          sayData[i].status = true;
          timer = setTimeout(() => {
            this.$refs.audio.muted = false;
            this.$refs.audio.play();
          }, (2500 * num));
          that.timers.push(timer);
          if (i === sayDataLastIndex) {
            that.sayIndex = sayDataLen;
          } else {
            num = num + 1;
          }
        }
      }
    },
    /**
     * 活动计时器
     */
    activityTiming() {
      const that = this;
      const start_time = that.start_time;
      const timeStamp = new Date().getTime();
      const startTimeStamp = new Date(start_time).getTime();
      let timeStampDiff = Math.floor((timeStamp - startTimeStamp) / 1000); // 将时间差转换成秒
      clearInterval(that.activityDurationTimer);
      that.activityDurationTimer = setInterval(function() {
        if (that.activityBegin) {
          const nextSecond = Number(that.activityDuration.second) + 1;
          if (nextSecond > 59) {
            that.activityDuration.second = '00';
            const nextMinute = Number(that.activityDuration.minute) + 1;
            if (nextMinute > 59) {
              that.activityDuration.minute = '00';
              const nextHour = Number(that.activityDuration.hour) + 1;
              that.activityDuration.hour = nextHour > 9 ? nextHour : '0' + nextHour;
            } else {
              that.activityDuration.minute = nextMinute > 9 ? nextMinute : '0' + nextMinute;
            }
          } else {
            const nextSecond = Number(that.activityDuration.second) + 1;
            that.activityDuration.second = nextSecond > 9 ? nextSecond : '0' + nextSecond;
          }
        } else {
          timeStampDiff = timeStampDiff + 1; // 时间差每秒 + 1
          that.activityBegin = !that.activityBegin;
          const hour = Math.floor(timeStampDiff / (60 * 60));
          const minute = Math.floor((timeStampDiff - (hour * 60 * 60)) / 60);
          const second = Math.floor(timeStampDiff - (hour * 60 * 60) - (minute * 60));
          that.activityDuration.hour = hour > 9 ? hour : '0' + hour;
          that.activityDuration.minute = minute > 9 ? minute : '0' + minute;
          that.activityDuration.second = second > 9 ? second : '0' + second;
        }
      }, 1000);
    },
  },
  created() {
    const that = this;
    clearInterval(that.timer);
    that.getList(false);
    that.timer = setInterval(() => {
      that.getList(true);
    }, 2000);
    /* const userData = JSON.parse(sessionStorage.getItem("init"));
    this.shopName = userData.shop.name; */
  },
  mounted() {
    setTimeout(() => {
      this.bgPosition = {
        mllPositionLeft: '0',
        mlrPositionRight: '0',
        bklPositionLeft: '0',
        bkrPositionRight: '0',
      }
    }, 200)
  },
  destroyed() {
    // 清除定时器
    clearInterval(this.timer);
    clearInterval(this.activityDurationTimer);
    const len = this.timers.length;
    const lastIndex = len - 1;
    for (let i = 0; i < len; i++) {
      clearTimeout(this.timers[i]);
      if (i === lastIndex) {
        this.timers = [];
        this.sayData = [];
      }
    }
    console.log("离开页面");
  }
}
</script>

<style lang="scss" scoped>
  .main {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    background-color: #A00707;
    .bg-area {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100vh;
      &-left {
        position: relative;
        overflow: hidden;
        width: 30%;
        height: 100%;
        .bg-img-ml {
          position: absolute;
          top: 0;
          left: -25vh;
          width: 25vh;
          height: 100vh;
          transition: linear left 1s;
        }
        .bg-img-bk {
          position: absolute;
          top: 0;
          left: -42vh;
          width: 42vh;
          height: 100vh;
          transition: linear left 1s;
        }
      }
      &-right {
        position: relative;
        overflow: hidden;
        width: 30%;
        height: 100%;
        .bg-img-ml {
          position: absolute;
          top: 0;
          right: -25vh;
          width: 25vh;
          height: 100vh;
          transition: linear right 1s;
        }
        .bg-img-bk {
          position: absolute;
          top: 0;
          right: -42vh;
          width: 42vh;
          height: 100vh;
          transition: linear right 1s;
        }
      }
    }
    .content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      min-width: 121.11vh;
      .activity {
        width: 100%;
        height: 100%;
        padding: 4.26vh 6.94vh 0 6.94vh;
        box-sizing: border-box;
        &-logo {
          display: flex;
          justify-content: center;
          align-items: center;
          .el-image__inner {
            width: 22.78vh;
            vertical-align: bottom;
          }
        }
        &-btn-area {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          height: 12.04vh;
          padding: 0 30px;
          box-sizing: border-box;
          &-item {
            margin-right: 15px;
            font-size: 28px;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
        &-name {
          margin-top: 1.39vh;
          font-size: 4.44vh;
          font-weight: bold;
          line-height: 8.5vh;
          color: #FFFFFF;
          text-align: center;
          text-shadow: 2px 3px 0 rgba(0, 0, 0, .16);
        }
        &-data-count {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 90vh;
          height: 5.56vh;
          margin: 0 auto;
          padding: 0 2.5vh;
          background-color: #B3180E;
          border-radius: 3.15vh;
          box-sizing: border-box;
          &-item {
            display: flex;
            align-items: center;
            font-size: 1.48vh;
            color: #FFFFFF;
            text-shadow: 2px 3px 0 rgba(0, 0, 0, .16);
            &-val {
              font-size: 3.33vh;
            }
          }
        }
        &-carousel {
          overflow: hidden;
          width: 90vh;
          height: 5.56vh;
          margin: 1.39vh auto 0;
          padding: 0 3.33vh;
          background-color: #B3180E;
          border-radius: 2.78vh;
          box-sizing: border-box;
          &-area {
            overflow: hidden;
            width: 100%;
            height: 5.56vh;
            padding-top: 0.93vh;
            .activity-carousel-item {
              float: left;
              display: flex;
              align-items: center;
              overflow: hidden;
              width: 18%;
              height: 3.7vh;
              margin-right: 2%;
              margin-bottom: 0.93vh;
              transition: ease margin-left .5s;
              &-avatar {
                overflow: hidden;
                width: 3.7vh;
                height: 3.7vh;
                border-radius: 50%;
                vertical-align: bottom;
                /deep/ .image-slot {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 3.7vh;
                  height: 3.7vh;
                  background-color: #CCCCCC;
                  font-size: 2.22vh;
                  color: #FFFFFF;
                  border-radius: 50%;
                }
              }
              &-data {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                overflow: hidden;
                height: 3.7vh;
                margin-left: 0.93vh;
                text-overflow: ellipsis;
                white-space: nowrap;
                .user-name {
                  overflow: hidden;
                  font-size: 1.3vh;
                  line-height: 1.3vh;
                  color: #FFFFFF;
                  text-align: left;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                .user-data {
                  overflow: hidden;
                  font-size: 1.3vh;
                  line-height: 1.3vh;
                  color: #FCFF00;
                  text-align: left;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
          &-nodata {
            font-size: 1.67vh;
            text-align: center;
            color: #FFFFFF;
            line-height: 5.56vh;
          }
        }
        &-total {
          position: relative;
          height: 27.87vh;
          &-sales {
            // height: 27.87vh;
            height: 26.11vh;
            font-size: 26.11vh;
            font-family: "Microsoft YaHei";
            font-weight: bold;
            color: #FCFF00;
            // line-height: 27.87vh;
            line-height: 26.11vh;
            text-align: center;
            text-shadow: 2px 3px 0 rgba(0, 0, 0, .16);
          }
          .activity-duration {
            position: absolute;
            left: 50%;
            bottom: 0;
            height: 4.44vh;
            margin-left: 36.48vh;
            font-size: 1.67vh;
            font-weight: bold;
            font-style: italic;
            line-height: 4.44vh;
            color: #FFFFFF;
            text-align: center;
            &-time {
              color: #FCFF00;
            }
          }
        }
        &-list {
          position: relative;
          overflow: hidden;
          width: 121.11vh;
          height: 40vh;
          margin: 0 auto;
          padding: 0 4.26vh;
          background: #B3180E;
          border-radius: 10px;
          box-sizing: border-box;
          &-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            &__item {
              display: flex;
              align-items: center;
              width: 33.8vh;
              height: 6.3vh;
              font-size: 2.04vh;
              font-weight: bold;
              color: #FFFFFF;
              text-shadow: 2px 3px 0 rgba(0, 0, 0, .16);
            }
          }
          &-area {
            position: absolute;
            top: 6.3vh;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            padding: 0 4.26vh;
            box-sizing: border-box;
            &__item::-webkit-scrollbar {
              display: none; /* Chrome Safari 隐藏滚动条 */
            }
            &__item {
              width: 33.8vh;
              height: 100%;
              scrollbar-width: none; /* firefox 隐藏滚动条 */
              -ms-overflow-style: none; /* IE 10+ 隐藏滚动条 */
              overflow-x: hidden;
              overflow-y: auto;
              &-content {
                overflow: hidden;
                border-radius: 10px;
                .activity-list-item {
                  display: flex;
                  align-items: center;
                  width: 33.8vh;
                  height: 6.48vh;
                  padding: 0.93vh 0;
                  background-color: #A8140B;
                  box-sizing: border-box;
                  &-rank {
                    display: flex;
                    justify-content: center;
                    width: 6.3vh;
                    &-icon {
                      width: 3.52vh;
                    }
                  }
                  &-avatar {
                    overflow: hidden;
                    width: 4.63vh;
                    height: 4.63vh;
                    border-radius: 50%;
                    background-color: #FFFFFF;
                    /deep/ .image-slot {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 4.63vh;
                      height: 4.63vh;
                      background-color: #CCCCCC;
                      font-size: 2.78vh;
                      color: #FFFFFF;
                      border-radius: 50%;
                    }
                  }
                  &-data {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 4.63vh;
                    margin-left: 1.11vh;
                    .user-name {
                      font-size: 1.48vh;
                      font-weight: bold;
                      text-align: left;
                      color: #FFFFFF;
                    }
                    .user-data {
                      display: flex;
                      flex-wrap: nowrap;
                      &-item {
                        overflow: hidden;
                        margin-right: 1.2vh;
                        font-size: 1.2vh;
                        color: #FFFFFF;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        &-val {
                          color: #FCFF00;
                        }
                      }
                      &-item:last-child {
                        margin: 0;
                      }
                    }
                  }
                }
                .activity-list-item:hover {
                  cursor: pointer;
                }
                &-nodata {
                  height: 33.7vh;
                  background: #A8140B;
                  font-size: 1.85vh;
                  line-height: 33.7vh;
                  color: #FFFFFF;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-height: 810px) {
    .main {
      height: 810px;
      .bg-area {
        height: 810px;
        &-left {
          .bg-img-ml {
            left: -203px;
            width: 203px;
            height: 810px;
          }
          .bg-img-bk {
            left: -340px;
            width: 340px;
            height: 810px;
          }
        }
        &-right {
          .bg-img-ml {
            right: -203px;
            width: 203px;
            height: 810px;
          }
          .bg-img-bk {
            right: -340px;
            width: 340px;
            height: 810px;
          }
        }
      }
      .content {
        min-width: 980px;
        min-height: 810px;
        .activity {
          padding: 35px 56px 0 56px;
          &-logo {
            .el-image__inner {
              width: 185px;
            }
          }
          &-btn-area {
            height: 98px;
          }
          &-name {
            font-size: 36px;
          }
          &-data-count {
            width: 729px;
            height: 50px;
            padding: 0 20px;
            border-radius: 25px;
            &-item {
              font-size: 12px;
              &-val {
                font-size: 27px;
              }
            }
          }
          &-carousel {
            width: 730px;
            height: 46px;
            margin: 11px auto 0;
            padding: 0 27px;
            border-radius: 23px;
            &-area {
              height: 46px;
              padding-top: 8px;
              .activity-carousel-item {
                height: 30px;
                margin-bottom: 8px;
                &-avatar {
                  width: 30px;
                  height: 30px;
                  /deep/ .image-slot {
                    width: 30px;
                    height: 30px;
                    font-size: 18px;
                  }
                }
                &-data {
                  height: 30px;
                  margin-left: 8px;
                  .user-name {
                    font-size: 10px;
                  }
                  .user-data {
                    font-size: 10px;
                  }
                }
              }
            }
            &-nodata {
              font-size: 14px;
              line-height: 46px;
            }
          }
          &-total {
            height: 226px;
            &-sales {
              height: 211px;
              font-size: 211px;
              line-height: 211px;
            }
            .activity-duration {
              height: 48px;
              margin-left: 295px;
              font-size: 14px;
              line-height: 48px;
            }
          }
          &-list {
            width: 980px;
            height: 324px;
            padding: 0 34px;
            &-title {
              &__item {
                width: 274px;
                height: 51px;
                font-size: 16px;
              }
            }
            &-area {
              top: 51px;
              padding: 0 34px;
              &__item {
                width: 274px;
                &-content {
                  .activity-list-item {
                    width: 274px;
                    height: 52px;
                    padding: 8px 0;
                    &-rank {
                      width: 52px;
                      &-icon {
                        width: 28px;
                      }
                    }
                    &-avatar {
                      width: 38px;
                      height: 38px;
                      /deep/ .image-slot {
                        width: 38px;
                        height: 38px;
                        font-size: 22px;
                      }
                    }
                    &-data {
                      height: 38px;
                      margin-left: 9px;
                      .user-name {
                        font-size: 12px;
                      }
                      .user-data {
                        &-item {
                          margin-right: 10px;
                          font-size: 10px;
                        }
                      }
                    }
                  }
                  &-nodata {
                    height: 273px;
                    font-size: 15px;
                    line-height: 273px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
