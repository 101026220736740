import { render, staticRenderFns } from "./chain_activity.vue?vue&type=template&id=cf1d5a22&scoped=true&"
import script from "./chain_activity.vue?vue&type=script&lang=js&"
export * from "./chain_activity.vue?vue&type=script&lang=js&"
import style0 from "./chain_activity.vue?vue&type=style&index=0&id=cf1d5a22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf1d5a22",
  null
  
)

export default component.exports